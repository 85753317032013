<template>
  <div class="table_container ml-2">
        <h2 class="hide_on_screen mb-7" style="margin-left: 40%">
      <span v-if="cmdType == 2">Offre devis</span>
      <span v-else>Packing list</span>
    </h2>
    <h3>
      <span class="hide_on_print">Client: </span>
      <input v-model="theClient" />
    </h3>
      <table
        class="minimalistBlack mt-3 tableFixHead"
        style="table-layout: fixed;"
        id="thetable"
      >
        <thead>
          <tr>
            <th id="ref_col" class="ref_print_width ref_screen_width">
              Référence
            </th>
            <th class="print_1_5">Quantité</th>
            <th class="print_6 screen_600">Désignation</th>
            <th class="print_1_5">P.U</th>
            <th class="hide_on_print basic-width">P.Promo</th>
            <th class="print_2">Total HT</th>
            <th class="print_1_5 basic-reduced-width">#</th>
            <th class="print_1_5 hide_on_print basic-reduced-width">SC</th>
            <th class="print_1_5 hide_on_print basic-reduced-width">C</th>
            <th class="hide_on_print basic-width">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, i) of products" :key="i">
            <td id="ref_col_td">
              <div>
                <input
                  style="width: 2.5cm"
                  onfocus="this.select()"
                  v-on:keyup.enter="
                    fetchProdByRefReplace(i + 1, product.reference)
                  "
                  class=""
                  :class="{'red-border': product.stock <= 1}"
                  v-model="product.reference"
                />
                <v-btn
                  style=""
                  text
                  class="hide_on_print"
                  @click.stop="showProductDetails(product)"
                >
                  <Magnify />
                </v-btn>
              </div>
            </td>
            <td class="ui-helper-center ">
              <input
                onfocus="this.select()"
                v-on:keyup.enter="focusNewRefInput"
                :id="'uniteDeVente_' + i"
                class="width_1_5 hide_on_print red--text"
                v-model="product.quantity"
              />
              <span class="hide_on_screen red--text">{{ product.quantity }}</span>
            </td>

            <td>
              <!--<span class="hide_on_print" style="width:100%; font-size: 11px" >{{ product.des2 }}</span>-->
              <input type="text" class="hide_on_print" :id="'des_col_td_' + i" style="width:100%; font-size: 12px" :value="product.des2" readonly>
              <div class="hide_on_screen">
                <span>{{ product.designation }} </span>
                <span v-if="product.reference != 'DIV'">
                  <span style="font-size: 11px">({{ product.dimensions }}) </span>
                  <span v-if="product.marque || product.gamme" style="font-weight: bold;"> | </span>
                  <span >{{ product.marque }} </span>
                  <span>{{ product.gamme }} </span>
                </span>
              </div>
            </td>

            

            <td class="ui-helper-center basic-width" style="font-weight: bold">
              <input
                v-on:keyup.enter="focusNewRefInput"
                :id="'prix_col_td_' + i"
                onfocus="this.select()"
                class="width_1_5 hide_on_print"
                v-model="product.prixUnitaire_tmp"
              />
              <span class="hide_on_screen">{{ product.prixUnitaire_tmp }}</span>
            </td>
            <td class="ui-helper-center hide_on_print basic-width">
              {{ product.prixPromo }}
            </td>
            <td class="ui-helper-center basic-width">
              {{ safeMultiply(product.prixUnitaire_tmp, product.quantity) }}
            </td>
            <td class="ui-helper-center basic-width">
              <input
                v-on:keyup.enter="focusNewRefInput"
                :id="'com_col_td_' + i"
                onfocus="this.select()"
                class="width_1_5 hide_on_print"
                v-model="product.commentaire"
              />
              <span class="hide_on_screen">{{ product.commentaire }}</span>
            </td>
            <td class="ui-helper-center hide_on_print basic-width">
              {{ product.sous_colisage }}
            </td>
            <td class="ui-helper-center hide_on_print basic-width">
              {{ product.colisage }}
            </td>
            <td class="hide_on_print">
              <v-btn text color="red" @click="deleteProductFromProducts(i)">
                <Delete />
              </v-btn>
            </td>
          </tr>
          <tr class="hide_on_print">
            <td>
              <input
                id="ref_input"
                class="ref_input"
                v-on:keyup.enter="fetchProdByRef"
                v-model="newRef"
              />
              <v-btn
                text
                class="hide_on_print"
                @click.stop="showProductDetails({})"
              >
                <Magnify />
              </v-btn>
            </td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
          </tr>
        </tbody>
      </table>
      <p style="text-align: right" class="mr-7 affichage-totalz">
        Total:
        {{
          products
            .map((x) => safeMultiply(x.quantity, x.prixUnitaire_tmp))
            .reduce((a, b) => safeAdd(a, b), 0)
        }}
        €
      </p>

    <v-dialog v-model="detailsDialog">
      <DetailsProductComponent
        v-if="selectedProductForDetails"
        :product="selectedProductForDetails"
      />
    </v-dialog>

    <br />
    <v-btn class="mt-5 green hide_on_print" dark @click="validateOrder"
      >valider commande</v-btn
    >
    <br />
    <v-btn class="mt-5 red hide_on_print" dark @click="clearProducts"
      >annuler commande</v-btn
    >
    <!-- 
    <p
      class="totalCommande"
    >Total:  €</p>
    -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DetailsProductComponent from "../components/DetailsProductComponent";
import Magnify from "vue-material-design-icons/Magnify.vue";
import Delete from "vue-material-design-icons/Delete.vue";
import bigDecimal from "js-big-decimal";
import $ from "jquery";
import ArrowTable from '../utils/arrow-table'
//import $ from "jquery";
export default {
  props: {cmdType: {default: 1}},
  data: () => ({
    newRef: "",
    selectedProductForDetails: null,
  }),
  components: {
    DetailsProductComponent,
    Magnify,
    // eslint-disable-next-line vue/no-unused-components
    Delete,
  },
  methods: {
    ...mapActions(["fetchProductsByReference", "saveOrder"]),
    ...mapMutations([
      "setErreurAjoutProduitFacture",
      "setProductDetailsDialog",
      "setClient",
      "deleteProductFromProducts",
      "clearProducts",
      'setCmdType'
    ]),
    fetchProdByRef() {
      this.fetchProductsByReference({ reference: this.newRef });
      //si pas d'erreur
      this.newRef = "";
    },
    fetchProdByRefReplace(replaceIndex, ref) {
      console.log("fetchprodreplace func, replaceindex -> ", replaceIndex);
      this.fetchProductsByReference({
        reference: ref,
        replaceIndex: replaceIndex,
      });
      //si pas d'erreur
      this.newRef = "";
    },
    focusNewRefInput() {
      setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0);
      let element = document.getElementById("ref_input");
      if (element != null) element.focus();
    },
    showProductDetails(product) {
      //console.log(product.designation);
      this.selectedProductForDetails = product;
      this.setProductDetailsDialog(true);
    },
    validateOrder() {
      // eslint-disable-next-line no-constant-condition
      if (this.products.length > 0 || true) {
        this.saveOrder({
          client: this.client,
          items: this.products.map((prod) => {
            //console.log('current des =>', currentDes)
            return {
              reference: prod.reference,
              quantity: prod.quantity,
              current_price: prod.prixUnitaire_tmp,
              montantNet: this.safeMultiply(
                prod.quantity,
                prod.prixUnitaire_tmp
              ),
              commentaire: prod.commentaire,
            };
          }),
          total: this.products
            .map((x) => this.safeMultiply(x.quantity, x.prixUnitaire_tmp))
            .reduce((a, b) => this.safeAdd(a, b), 0),
          cmdType: this.globalCmdType || 1
        });
      }
    },
    monter(lin, id) {
      lin--;
      //console.log('go to', id+lin)
      let element = document.getElementById(id + lin);
      //console.log(element)
      if (element != null) element.focus();
    },
    descendre(lin, id) {
      lin++;
      //console.log('go to', id+lin)
      let element = document.getElementById(id + lin);
      //console.log(element)
      if (element != null) element.focus();
    },
    safeMultiply(a, b) {
      return bigDecimal.multiply(a, b) * 1;
    },
    safeAdd(a, b) {
      return bigDecimal.add(a, b) * 1;
    },
  },
  computed: {
    ...mapGetters([
      "products",
      "client",
      "erreurAjoutProduitFacture",
      "productDetailsDialog",
      'globalCmdType'
    ]),
    detailsDialog: {
      get: function () {
        return this.productDetailsDialog;
      },
      set: function (bool) {
        this.setProductDetailsDialog(bool);
      },
    },
    theClient: {
      get() {
        return this.client;
      },
      set(x) {
        this.setClient(x);
      },
    },
    nbLines() {
      return this.products.length;
    },
  },
  watch: {
    products() {
      //let newRef = maj.slice(-1)[0]
      //console.log(maj, ancien, ancien.length)
      //console.log('uniteDeVente_'+newRef.reference)
      console.log("uniteDeVente_" + (this.products.length - 1));
      setTimeout(() => {
        let element = document.getElementById(
          "uniteDeVente_" + (this.products.length - 1)
        );
        if (element != null) element.focus();
      }, 0);
      localStorage.setItem(
        "currentCommandeProducts",
        JSON.stringify(this.products)
      );
    },
    erreurAjoutProduitFacture(maj) {
      console.log("erreur ajout changed", maj);
      /*
      if(maj.split(',').length == 2) {
        switch(maj.split(',')[0]) {
          case 'doublon': 
            console.log('doublon')
            setTimeout(() => {
              document.getElementById('uniteDeVente_'+maj.split(',')[1]).focus();
            }, 0)
            break;
        }
      }*/
    },
    // eslint-disable-next-line no-unused-vars
    newRef(maj) {
      /*
      if(maj.length >= 13) {
        this.fetchProdByRef()
      }
      */
    },
    productDetailsDialog(val) {
      if (!val) this.selectedProductForDetails = null;
    },
  },
  updated() {},
  mounted() {
    this.focusNewRefInput();
    /* get products from localStorage */
    // eslint-disable-next-line no-undef
    new ArrowTable($('#thetable'), {
    enabledKeys: ['left', 'right', 'up', 'down']
    })
    //$("#thetable").arrowTable({
      //enabledKeys: ["left", "right", "up", "down"],
    //});
  },
  created() {
    /*if(this.client == '') {
      this.$router.replace({name: 'Home'})
    }*/
    console.log('facturation created, cmdType ->', this.cmdType)
    
  },
};
</script>

<style scoped>
.red-border {
  border: 1px solid red;
}
#total {
  text-align: right;
}
.affichage-total {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
@media screen {
  .basic-width {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }
  .basic-reduced-width {
    width: 75px !important;
    min-width: 75px !important;
    max-width: 75px !important;
  }
  .hide_on_screen {
    display: none;
  }
  .totalCommande {
    position: fixed;
    bottom: 1em;
    right: 3em;
  }
  .tableFixHead {
    overflow-y: auto;
    height: 100px;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }
  .v-card {
    overflow: auto;
  }
  .width_1_5 {
    width: 1.5cm;
  }
  .ref_input {
    width: 3.3cm;
  }
  .ref_screen_width {
    width: 200px;
  }
  .screen_600 {
    width: 800px;
  }
  table.minimalistBlack {
    border: 1px solid #000000;
    text-align: left;
    border-collapse: collapse;
    /*width: 100%;*/
  }
  table.minimalistBlack td,
  table.minimalistBlack th {
    border: 0.5mm solid #000000;
    padding: 5px 4px;
  }
  table.minimalistBlack tbody td {
    font-size: 14px;
    text-align: center;
  }
  table.minimalistBlack thead {
    border: 0.5mm solid #000000;
  }
  table.minimalistBlack thead th {
    font-size: 16px;
    font-weight: bold;
    color: rgb(238, 97, 97);
    text-align: center;
  }
  #ref_col {
    /*text-align: left;*/
  }
  #ref_col_td {
    text-align: right !important;
    width: 170px;
    min-width: 170px;
  }
  table.minimalistBlack tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    border: 1px solid #000000;
  }
  table.minimalistBlack tfoot td {
    /*font-size: 14px;*/
    border: 0.5mm solid black;
  }
}

@media print {
  .ui-helper-center {
    text-align: center;
  }
  .table_container {
    padding-left: 0.7cm;
  }
  .ref_print_width {
    width: 3cm;
  }
  .hide_on_print {
    display: none;
  }
  .print_1_5 {
    width: 1.5cm;
  }
  .print_1 {
    width: 75px;
    min-width: 75;
    max-width: 75px;

  }
  .print_2 {
    width: 2cm;
  }
  .print_6 {
    width: 9cm;
  }

  .width_1_5 {
    width: 1.5cm;
  }
  .ref_input {
    width: 2cm;
  }

  table.minimalistBlack {
    width: 20cm;
    border: 1px solid #000000;
    text-align: left;
    border-collapse: collapse;
  }
  table.minimalistBlack td,
  table.minimalistBlack th {
    border: 0.5mm solid #000000;
  }
  table.minimalistBlack tbody td {
    font-size: 14px;
  }
  table.minimalistBlack thead {
    border-bottom: 0.5mm solid #000000;
  }
  table.minimalistBlack thead th {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    text-align: center;
  }
  #ref_col {
    text-align: left;
  }

  table.minimalistBlack tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    border-top: 1px solid #000000;
  }
  table.minimalistBlack tfoot td {
    /*font-size: 14px;*/
    border: 0.5mm solid black;
  }
}
</style>